<template>
  <v-main class="natural lighten-3">
    <v-container fluid>
      <v-row>
        <v-col cols="12" v-for="(item, index) in items" :key="index">
          <RecordCard
            :name="item.arrResult[0].name_th"
            :cid="item.arrResult[0].id_number"
            :timestamp="item.arrResult[0].createDate"
            mode="waiting"
            :formTypeName="item.formTypeName"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import RecordCard from "@/components/recorddaily/RecordCard";
import { postAwaitFormResult } from "@/api/";
export default {
  components: {
    RecordCard,
  },
  data() {
    return {
      items: [],
      role: null,
      loading: false,
    };
  },
  methods: {
    async pushOfflineData() {
      if (navigator.onLine) {
        let offlineItem = this.$offlineStorage.get("formOffline");
        if(offlineItem)
        {
        try {
          for (let i = 0; i < offlineItem.length; i++) {
            let data = offlineItem[i][1] 
            try{
            const message = await postAwaitFormResult({ data })
           if (message.data.code === 1) {
                  offlineItem[i][1].status = "success";
                }else{
                  offlineItem[i][1].status = "fail";
                }
           
            }catch(err){
            offlineItem[i][1].status = "fail";
            }
          }
        } finally {
           for (let i = 0; i < offlineItem.length; i++) {
             if(offlineItem[i][1].status === 'success'){
               offlineItem.splice(i,1)
             }

           }
           this.$offlineStorage.set("formOffline",offlineItem);
           //console.log(offlineItem)
        }
        }

      }
    },
    fetchData() {
      try {
        this.loading = true;
        this.role = this.$offlineStorage.get("role");
        let offline = this.$offlineStorage.get("formOffline");
        if (offline) {
          this.items = offline
            .filter((item) => item[0] === this.role.uuid.mobile)
            .map((item) => item[1]);
        }
      } catch (ex) {
        console.log(ex);
      } finally {
        this.loading = false;
        //this.pushOfflineData()
      }
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.pushOfflineData()
        this.fetchData();
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  },
};
</script>

<style>
</style>